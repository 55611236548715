<template>
  <div :class="className">
    <div class="flex flex-wrap -mx-2">
      <div class="flex-grow px-2">
        <label :for="id" v-if="label" class="block mb-2">{{ label }}</label>
      </div>
      <div class="flex-shrink px-2">
        <tooltip v-if="tooltip">{{ tooltip }}</tooltip>
      </div>
    </div>

    <div v-if="Array.isArray(content)" class="max-h-200 overflow-y-scroll" :class="computedClass">
      <checkbox-input-component
        v-for="(option, i) in options"
        :key="i"
        v-model="content"
        :label="option[optionKey]"
        :option-value="option[optionValue]"
        class-name="mb-3"
        @input="handleInput"
      />
    </div>
    <div v-else class="caret-down">
      <select
        :id="id"
        v-model="content"
        @change="handleInput"
        :class="computedClass"
        :disabled="disabled || options.length === 0"
      >
        <option :value="null" v-if="placeholder">{{ placeholder }}</option>
        <option
          v-for="(option, i) in options"
          :key="i"
          :value="option[optionValue]"
        >{{ option[optionKey] }}</option>
      </select>
    </div>
    <div v-if="error" class="text-red-600 text-sm mt-2 font-bold">
      <i class="fas fa-exclamation-triangle"></i>
      {{ error }}
    </div>
  </div>
</template>

<script>
import randomstring from 'randomstring'
import CheckboxInputComponent from './CheckboxInputComponent.vue'

export default {
  components: { CheckboxInputComponent },
  props: {
    className: {
      type: String,
      default: 'mb-6'
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    value: {},
    placeholder: {
      type: String
    },
    tooltip: {
      type: String
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    optionKey: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'id'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedClass: function () {
      let c =
        'w-full py-2 pl-3 pr-8 border-2 rounded bg-white outline-none focus:border-accent-500 appearance-none'

      if (this.disabled || this.options.length === 0) {
        c += ' bg-gray-200 border-gray-300 text-gray-500'
      } else if (this.error) {
        c += ' border-red-600 hover:border-red-700'
      } else {
        c += ' border-gray-300 hover:border-gray-400'
      }

      return c
    }
  },
  data () {
    return {
      id: randomstring.generate(),
      content: this.value
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.max-h-200 {
  max-height: 200px;
}
.caret-down {
    position: relative;

    &::after {
        @apply text-gray-600;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\F0D7';
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
    }

    &:hover::after {
        @apply text-gray-800;
    }
}
</style>
