<template>
  <div>
    <h1 class="font-bold text-xl mb-6">Vendor Interviews - Job List</h1>

    <loader v-if="jobs === null" />
    <div v-else>
      <div class="flex flex-wrap -mx-2">
        <div class="w-full lg:w-1/3 px-2">
          <text-input v-model="searchParams.q" label="Search" />
        </div>
        <div class="w-full lg:w-1/3 px-2">
          <select-input v-model="searchParams.status" :options="statusOptions" label="Status"
          placeholder="[All Statuses]" />
        </div>
      </div>

      <div v-if="jobs.data.length === 0" class="italic">
        <i class="fas fa-info-circle"></i> Your jobs list is currently empty.
      </div>
      <div v-else>
        <div class="overflow-auto px-2">
          <table class="text-sm">
            <thead class="whitespace-nowrap text-left">
              <tr>
                <th class="p-2 whitespace-no-wrap cursor-pointer" @click="changeOrder('id')">#
                  <i v-if="searchParams.order_attribute === 'id'" class="fas" :class="searchParams.order_direction === 'desc' ? 'fa-caret-up' : 'fa-caret-down'"></i>
                </th>
                <th class="p-2">Vendor</th>
                <th class="p-2">Property</th>
                <th class="p-2">Office</th>
                <th class="p-2 text-center whitespace-no-wrap cursor-pointer" @click="changeOrder('created_at')">Received
                  <i v-if="searchParams.order_attribute === 'created_at'" class="fas" :class="searchParams.order_direction === 'desc' ? 'fa-caret-up' : 'fa-caret-down'"></i>
                </th>
                <th class="p-2 text-center whitespace-no-wrap cursor-pointer" @click="changeOrder('scheduled_at')">Scheduled
                  <i v-if="searchParams.order_attribute === 'scheduled_at'" class="fas" :class="searchParams.order_direction === 'desc' ? 'fa-caret-up' : 'fa-caret-down'"></i>
                </th>
                <th class="p-2 text-center">SLA</th>
                <th class="p-2 text-center">Status</th>
                <th class="p-2 text-center">Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="job in jobs.data" :key="job.id" class="border-t border-gray-200" @click="$router.push(`/job/${job.id}`)">
                <td class="px-2 py-4 border-l-4" :class="{'border-red-500': job.status === 'Needs Scheduling', 'border-green-500': job.status === 'Complete', 'border-yellow-500': job.status === 'Awaiting Upload'}">{{ job.id }}</td>
                <td class="px-2 py-4">{{ job.vendors[0].name }}</td>
                <td class="px-2 py-4">{{ job.property.address }}</td>
                <td class="px-2 py-4">{{ job.office.name }}</td>
                <td class="px-2 py-4 text-center">
                  {{ job.created_at | date }}
                  <br />
                  <small class="text-gray-800">{{ job.created_at | short_day }} - {{ job.created_at | time }}</small>
                </td>
                <td class="px-2 py-4 text-center">
                  <span v-if="job.scheduled_at">
                    {{ job.scheduled_at | date }}
                    <br />
                    <small class="text-gray-800">{{ job.scheduled_at | short_day }} - {{ job.scheduled_at | time }}</small>
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <td class="px-2 py-4 text-center" :class="{'text-red-500': job.status === 'Needs Scheduling' || job.status === 'Scheduled', 'text-green-500': job.status === 'Complete', 'text-yellow-500': job.status === 'Awaiting Upload'}">
                  <i class="far fa-clock"></i>
                  <div class="whitespace-nowrap" v-if="job.status === 'Needs Scheduling'">
                    +2 days
                  </div>
                </td>
                <td class="px-2 py-4 text-center" :class="{'text-red-500': job.status === 'Needs Scheduling'}">
                  {{ job.status }}
                </td>
                <td class="px-2 py-4 text-center border-r-4" :class="{'border-red-500': job.status === 'Needs Scheduling', 'border-green-500': job.status === 'Complete', 'border-yellow-500': job.status === 'Awaiting Upload'}">
                  <router-link :to="`/job/${job.id}`" v-if="!job.deleted_at">
                    <i class="fas fa-pencil-alt cursor-pointer"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <paginator v-model="searchParams.page" :results="jobs.meta" />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import api from "@/http";
import Loader from "@/components/Loader";
import Paginator from "@/components/PaginatorComponent";
import TextInput from '@/components/TextInputComponent.vue';
import SelectInput from '@/components/SelectInputComponent.vue';
import { mapState } from 'vuex/dist/vuex.common.js';

export default {
  components: {
    Loader,
    Paginator,
    TextInput,
    SelectInput
  },
  data() {
    return {
      jobs: null,
      searchParams: {
        q: '',
        status: 'Active',
        page: 1,
        order_attribute: 'created_at',
        order_direction: 'desc'
      },
      pageHasChanged: false,
      statusOptions: [
        {name: '[All Active Statuses]', id: 'Active'},
        {name: 'Needs Scheduling', id: 'Needs Scheduling'},
        {name: 'Scheduled', id: 'Scheduled'},
        {name: 'Awaiting Upload', id: 'Awaiting Upload'},
        {name: 'Complete', id: 'Complete'},
        {name: 'Cancelled', id: 'Cancelled'},
      ]
    }
  },
  computed: {
    ...mapState(['system'])
  },
  created() {
    this.search()
  },
  methods: {
    search: debounce(function () {
      this.$router.replace({ query: this.searchParams })

      if (this.pageHasChanged) {
        this.pageHasChanged = false
      } else {
        this.searchParams.page = 1
      }

      api.get('/interviewer/jobs', {
        params: {
          ...this.searchParams,
          network_id: this.system.network_id
        }
      }).then((response) => {
        this.jobs = response.data
      })
    }, 500),
    changeOrder (attribute) {
      this.searchParams.page = 1

      if (attribute === this.searchParams.order_attribute) {
        this.searchParams.order_direction = this.searchParams.order_direction === 'desc' ? 'asc' : 'desc'
        return
      }

      this.searchParams.order_attribute = attribute
      this.searchParams.order_direction = 'desc'
    },
  },
  watch: {
    'searchParams.page': function () {
      this.pageHasChanged = true
    },
    searchParams: {
      deep: true,
      handler: function (newVal) {
        if (newVal.page === this.previousPage) {
          this.searchParams.page = 1
        }
        this.search()
      }
    }
  }
}
</script>