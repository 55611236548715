<template>
  <div :class="className">
    <div class="flex flex-wrap -mx-2">
      <div class="flex-grow px-2">
        <label :for="id" v-if="label" class="block mb-2">{{ label }}</label>
      </div>
      <div class="flex-shrink px-2">
        <tooltip v-if="tooltip">
          {{ tooltip }}
        </tooltip>
      </div>
    </div>

    <input
      type="text"
      :id="id"
      v-model="content"
      @input="handleInput"
      :class="computedClass"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div v-if="error" class="text-red-600 text-sm mt-2 font-bold">
      <i class="fas fa-exclamation-triangle"></i>
      {{ error }}
    </div>
  </div>
</template>

<script>
import randomstring from 'randomstring'

export default {
  props: {
    className: {
      type: String,
      default: 'mb-6'
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    value: {
    },
    placeholder: {
      type: String
    },
    tooltip: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedClass: function () {
      let c =
        'w-full py-2 px-3 border-2 rounded bg-white outline-none focus:border-accent-600 appearance-none'

      if (this.disabled) {
        c += ' bg-gray-200 border-gray-300 text-gray-500'
      } else if (this.error) {
        c += ' border-red-600 hover:border-red-700'
      } else {
        c += ' border-gray-300 hover:border-gray-400'
      }

      return c
    }
  },
  data () {
    return {
      id: randomstring.generate(),
      content: this.value
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  }
}
</script>
